import { Radio } from "antd";
import './style.scss';

const DateRangeRadio = ({
  label = 'Select the date range:',
  options = [
    {
      value: 'day',
      label: 'Daily',
    },
    {
      value: 'week',
      label: 'Weekly',
    },
    {
      value: 'month',
      label: 'Monthly',
    },
    {
      value: 'year',
      label: 'Yearly',
    },
  ],
  rangeType,
  setRangeType
}) => (
  <div className="dateRangeRadio form-item">
    {label &&
      <div className="form-item-label">
        {label}
      </div>
    }
    <Radio.Group
      defaultValue={rangeType}
      size="large"
      onChange={(el) => setRangeType(el.target.value)}
    >
      {options && options.map((opt, key) =>
        <Radio.Button className="dateRangeRadio-item" key={key} value={opt.value}>{opt.label}</Radio.Button>
      )}
    </Radio.Group>
  </div>
);

export default DateRangeRadio;