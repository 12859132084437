import axios from 'axios';
import moment from 'moment';

// Configurando a instância do Axios
const api = axios.create({
    baseURL: 'https://apiv2.servicemasterapp.com', // Certifique-se de que este é o seu domínio
    //baseURL: 'http://localhost:8000',
    headers: {
        'Content-Type': 'application/json',
    },
});

// Interceptor para adicionar o token de autenticação a cada requisição
api.interceptors.request.use(
    config => {
        const token = localStorage.getItem('access_token'); // Supondo que o token é armazenado no localStorage
        if (token) {
            config.headers.Authorization = `Bearer ${token}`;
        }
        return config;
    },
    error => {
        return Promise.reject(error);
    }
);

// Função para buscar dados do gráfico
export const fetchChartData = async (viewType, startDate, endDate, locationCodes) => {
    try {
        const response = await api.get('/data/fetch_chart_data/', {
            params: {
                view_type: viewType,
                start_date: moment(startDate).format('YYYY-MM-DD'),
                end_date: moment(endDate).format('YYYY-MM-DD'),
                location_codes: locationCodes,
            },
        });
        return response.data;
    } catch (error) {
        console.error('Error fetching chart data:', error);
        throw error;
    }
};

// Função para buscar dados do gráfico por máquina
export const fetchChartDataByMachine = async (viewType, startDate, endDate, locationCodes, node) => {
    try {
        const response = await api.get('/data/fetch_chart_data_by_machine/', {
            params: {
                view_type: viewType,
                start_date: moment(startDate).format('YYYY-MM-DD'),
                end_date: moment(endDate).format('YYYY-MM-DD'),
                location_codes: locationCodes,
                node,
            },
        });
        return response.data;
    } catch (error) {
        console.error('Error fetching chart data by machine:', error);
        throw error;
    }
};

// Função para buscar dados por intervalo
export const fetchIntervalData = async (viewType, startDate, endDate, locationCodes) => {
    try {
        const response = await api.get('/data/fetch_interval_data/', {
            params: {
                view_type: viewType,
                start_date: moment(startDate).format('YYYY-MM-DD'),
                end_date: moment(endDate).format('YYYY-MM-DD'),
                location_codes: locationCodes,
            },
        });
        return response.data;
    } catch (error) {
        console.error('Error fetching interval data:', error);
        throw error;
    }
};

// Função para buscar dados por intervalo e por máquina
export const fetchIntervalDataByMachine = async (viewType, startDate, endDate, locationCodes, node) => {
    try {
        const response = await api.get('/data/fetch_interval_data_by_machine/', {
            params: {
                view_type: viewType,
                start_date: moment(startDate).format('YYYY-MM-DD'),
                end_date: moment(endDate).format('YYYY-MM-DD'),
                location_codes: locationCodes,
                node,
            },
        });
        return response.data;
    } catch (error) {
        console.error('Error fetching interval data by machine:', error);
        throw error;
    }
};

// Função para buscar dados por intervalo e por local
export const fetchIntervalDataByLocation = async (viewType, startDate, endDate, locationId) => {
    try {
        const response = await api.get('/data/fetch_interval_data_by_location/', {
            params: {
                view_type: viewType,
                start_date: moment(startDate).format('YYYY-MM-DD'),
                end_date: moment(endDate).format('YYYY-MM-DD'),
                location_id: locationId,
            },
        });
        return response.data;
    } catch (error) {
        console.error('Error fetching interval data by location:', error);
        throw error;
    }
};
