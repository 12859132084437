import React, {useEffect} from 'react';
import {useNavigate, useOutletContext} from "react-router-dom";
import {Button, Checkbox, Form, Input, message} from 'antd';
import {ArrowRightOutlined} from '@ant-design/icons';
import './style.scss';
import {useStoreInContext} from 'store/main'; // Ajuste o caminho conforme necessário
import ForgotPassword from "./ForgotPassword";
import {handleLogin} from '../../api/authService';
import {getUserInfo, listLocations, listMachines} from "../../api/smaService";

function Login() {
    const navigate = useNavigate();
    const {setDrawerOpen, setDrawerProps} = useOutletContext();

    // Utilização do Zustand store para obter o loggedUser
    const loggedUser = useStoreInContext(state => state.loggedUser);
    const { setLoggedUser, setLocations, setMachines } = useStoreInContext(state => state);

    useEffect(() => {
        // Se loggedUser está definido, redireciona para o dashboard

        let access_token = localStorage.getItem('access_token');

        if (loggedUser || access_token) {
            getUserInfo(access_token).then(requestProfileInfo => {
                console.log("Relogin: ", requestProfileInfo);
                if(requestProfileInfo.success){
                    let refresh_token = localStorage.getItem('refresh_token');
                    const userData = {
                        access_token,
                        refresh_token,
                        role: requestProfileInfo.data.role,
                        name: requestProfileInfo.data.name,
                        email: requestProfileInfo.data.email,
                        company: requestProfileInfo.data.company
                    };

                    setLoggedUser(userData);

                    listLocations().then(result => {
                        if (result.success) {
                            setLocations(result.data);
                        } else {
                            message.error(result.message);
                        }
                    });

                    listMachines().then(result => {
                        console.log("Teste Machines: ", result.data);
                        setMachines(result.data);
                    });

                    if (userData.role === 'ceo' || userData.role === 'manager') {
                        navigate("/dashboard");
                    }else if(userData.role === 'technician'){
                        navigate("/tasks");
                    }
                }
            });


        }
    }, [loggedUser, setMachines, setLocations, navigate, setLoggedUser]);  // Dependências no array para garantir a reação a mudanças

    const handleResetPassword = () => {
        setDrawerProps({
            closable: false,
            push: true,
            title: 'Recover your account',
            content: <ForgotPassword/>
        });
        setDrawerOpen(true);
    };

    const onFinish = async (values) => {
        await handleLogin(values.email, values.password, setLoggedUser, setLocations, setMachines, navigate);
    };

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
        message.error('Login failed! Please check your inputs and try again.');
    };

    return (
        <div className="Login">
            <h1>Access your<br/>SMA Dashboard</h1>
            <div className="login-form">
                <Form
                    name="login-form"
                    layout="vertical"
                    wrapperCol={{span: 16}}
                    style={{maxWidth: 600}}
                    initialValues={{remember: true}}
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    autoComplete="off"
                >
                    <Form.Item
                        label="Email"
                        name="email"
                        rules={[{required: true, message: 'Please input your email!'}]}
                    >
                        <Input placeholder="Your email"/>
                    </Form.Item>

                    <Form.Item
                        label="Password"
                        name="password"
                        rules={[{required: true, message: 'Please input your password!'}]}
                    >
                        <Input.Password placeholder="Your password"/>
                    </Form.Item>

                    <Form.Item
                        name="remember"
                        valuePropName="checked"
                    >
                        <Checkbox>Remember me</Checkbox>
                    </Form.Item>

                    <div
                        className="link-text text-center mb-lg"
                        onClick={handleResetPassword}
                    >
                        Forgot your password?
                    </div>

                    <Form.Item>
                        <Button type="primary" htmlType="submit">
                            Sign in
                            <ArrowRightOutlined/>
                        </Button>
                    </Form.Item>
                </Form>
            </div>
        </div>
    );
}

export default Login;
